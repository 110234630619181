import { Link } from '@remix-run/react'
import { cn } from '~/utils/misc.tsx'

export const AuthLayout = ({ children, className }: any) => {
  return (
    <div
      className={cn(
        'mx-4 flex h-screen items-center justify-center',
        className,
      )}
    >
      <div>
        <Link to="/" className="font-semibold text-foreground-muted">
          {`< Volver a Inicio`}
        </Link>
        <div className="max-w-[500px] bg-card p-[24px] pb-[60px] pt-[16px] sm:p-[48px]">
          <div className="mx-auto w-full max-w-md">
            <div className="flex flex-col gap-3">
              <Link to="/">
                <img src="/img/miistico-gris.png" alt="miistico logo" />
              </Link>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
